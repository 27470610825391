import { survey } from './survey'
import { assets } from './assets'
import { mapStyle } from './map'
import { serviceChangeFields } from '../shared/search'

const config = {
  id: 'default',
  // fonts: {
  //   google: {
  //     families: ['Mulish:300,400,500,700,800,900'],
  //   },
  // },
  options: {
    title: 'WQPHN',
    categoryMode: 'single',
    // combineMode: 'commissioned-services',
    combineMode: 'filters',
    informationCategories: false,
    defaultCategoryTitle: 'Healthcare Services',
    defaultCategorySuffix: 'Services',
  },

  meta: {
    templates: {
      title: '{{ tenant.options.title }}',
      description: '{{ tenant.options.title }}',
    },
  },

  routes: [
    {
      path: '/',
      exact: true,
      component: 'Search',
    },
    {
      path: '/support',
      component: 'Support',
      container: 'ContentPage',
      props: {
        contentId: 'support',
      },
      meta: {
        templates: {
          title: '{{ tenant.options.title }} - Site Support',
        },
      },
    },
    {
      path: '/location/:lat/:lng',
      component: 'LocationRedirect',
      props: {
        redirectTo: '/',
      },
    },
    {
      path: '/404',
      exact: true,
      component: 'NotFound',
      container: 'ContentPage',
      containerProps: {
        backButton: false,
      },
      meta: {
        templates: {
          title: '{{ tenant.options.title }} - 404 Not Found',
        },
      },
    },
    {
      path: '*',
      component: 'Redirect',
      props: {
        to: '/404',
      },
    },
  ],

  ui: {
    appBar: {
      elevation: 0,
      groups: [
        {
          align: 'fill',
          items: [
            {
              component: 'MenuButton',
              props: {
                sx: { px: 2 },
              },
            },
            {
              component: 'AppBarTitle',
              props: {
                disableTextTitle: true,
                href: 'https://www.wqphn.com.au/',
              },
            },
            {
              component: 'PortalOutlet',
              props: {
                sx: {
                  width: { lg: 800 },
                  flexGrow: { md: 1, lg: 0 },
                  display: { xs: 'none', lg: 'flex' },
                },
              },
            },
          ],
        },
      ],
    },

    menus: {
      navigation: {
        items: [
          {
            id: 'home',
            label: 'Home',
            icon: 'fas fa-home-alt',
            route: '/',
          },
          {
            id: 'support',
            label: 'Site Support',
            icon: 'fas fa-user-headset',
            route: '/support',
          },
          {
            id: 'reset',
            label: 'Clear Settings & Restart',
            icon: 'fas fa-undo',
            function: 'reset',
          },
        ],
      },
    },

    search: {
      tabs: [
        {
          id: 'services',
          title: 'Services',
          icon: 'fas fa-building',
          component: 'SearchResults',
          map: true,
          options: {
            search: 'services',
          },
        },
        {
          id: 'favourites',
          title: 'Favourites',
          icon: 'fas fa-star',
          component: 'SearchResults',
          map: true,
          options: {
            search: 'favourites',
          },
        },
      ],

      desktop: {
        paramsDrawer: {
          breakpoint: 'lg',
        },
        disableParams: true,
      },

      map: {
        locationZoom: 6,
        selectionZoom: 17,
        style: mapStyle,
        locationBoundary: {
          show: true,
          pathOptions: {
            stroke: true,
            color: '#329ec5',
            weight: 1.5,
            opacity: 0.6,
            fill: true,
            fillColor: '#329ec5',
            fillOpacity: 0.2,
          },
        },
        defaultView: {
          center: { lat: -25.461861062505264, lng: 122.18363055754051 },
          zoom: 6,
        },
        zoomLevels: {
          geography: {
            2288: 6, // nq
            //91: 7, // Cairns and Hinterland
            // 90: 8, // Central Queensland
            88: 7, // Central West
            // 79: 7, // Darling Downs
            // 80: 10, // Gold Coast
            //81: 8, // Mackay
            // 92: 10, // Metro North
            // 82: 9, // Metro South
            89: 7, // North West
            83: 7, // South West
            // 84: 9, // Sunshine Coast
            //93: 7, // Torres and Cape
            //85: 7, // Townsville
            // 86: 9, // West Moreton
            // 87: 8, // Wide Bay
          },
        },
      },

      parameters: {
        query: {
          id: 'query',
          type: 'query',
          label: 'Search',
          icon: 'far fa-search',
        },
        location: {
          id: 'location',
          type: 'location',
          label: 'Location',
          icon: 'fas fa-map-marker-question',
        },
        geographySelect: {
          id: 'location',
          type: 'geographySelect',
          label: 'Region',
          icon: 'fas fa-map-marker-question',
          queryKey: 'geographySelect',
          sources: [
            {
              type: 'geography',
              id: 2298,
              label: 'All Regions',
              default: true,
            },
            { type: 'dataset', id: 'WQPHN' },
          ],
        },
        categories: {
          id: 'categories',
          type: 'categoryButtons',
          label: 'Categories',
          icon: 'far fa-stream',
        },
        categorySelect: {
          id: 'categories',
          type: 'categorySelect',
          label: 'Categories',
          icon: 'far fa-stream',
        },
      },

      parameterGroups: {
        desktop: {
          controls: [
            { param: 'query' },
            { param: 'geographySelect' },
            { param: 'categorySelect' },
          ],
        },
        appBar: {
          disableLabels: true,
          containerProps: {
            columns: 3,
            spacing: 1,
            sx: { px: 3, py: 0, width: '100%' },
          },
          controls: [
            { param: 'query', props: { showSearchIcon: true } },
            { param: 'geographySelect' },
            { param: 'categorySelect' },
          ],
        },
        mobileSearch: {
          controls: [
            { param: 'query' },
            { param: 'geographySelect' },
            { param: 'categorySelect' },
          ],
        },
      },

      resultsList: {
        default: {
          sections: [
            {
              type: 'initBlock',
              items: [{ component: 'Welcome' }],
            },
            {
              type: 'resultsBlock',
              items: [{ component: 'Results' }],
            },
            {
              type: 'statusBlock',
              items: [{ component: 'Status' }, { component: 'SiteSupport' }],
            },
          ],
        },
        favourites: {
          sections: [
            {
              type: 'initBlock',
              items: [{ component: 'Welcome' }],
            },
            {
              type: 'resultsBlock',
              items: [{ component: 'Results' }],
            },
            {
              type: 'statusBlock',
              items: [
                { component: 'FavouritesStatus' },
                { component: 'SiteSupport' },
              ],
            },
          ],
        },
      },

      resultItem: {
        service: {
          components: [
            {
              component: 'BadgeList',
              hideVariant: 'map',

              options: {
                icon: 'default',
                styles: {
                  inactive: {
                    backgroundColor: '#bbb',
                  },
                },
              },
            },
            {
              component: 'Title',
              showCategory: false,
            },
            {
              component: 'Details',
              fields: [
                { key: 'operatingDays', icon: 'far fa-clock' },
                { key: 'address', icon: 'fas fa-map-marker-alt' },
                { key: 'phone', icon: 'fas fa-phone' },
              ],
            },
            {
              component: 'AddressWithheld',
              hideVariant: 'list',
            },
            {
              component: 'Description',
              hideVariant: 'map',
            },
            {
              component: 'ServiceChanges',
              fields: serviceChangeFields,
              hideVariant: 'map',
            },
            {
              component: 'HealthEngine',
              hideVariant: 'map',
            },
          ],
        },
      },
    },
  },

  /*
  footer: {
    backgroundColor: '#ddd',
    columns: ['cie'],
  },
  */

  categories: [
    {
      id: 'first-nations-health',
      title: 'First Nations Health',
      search: true,
      searchOptions: {
        filter: {
          tags: [1786],
        },
      },
    },
    {
      id: 'older-persons-and-palliative-health',
      title: 'Older Persons and Palliative Health',
      search: true,
      searchOptions: {
        filter: {
          tags: [1787],
        },
      },
    },
    {
      id: 'alcohol-and-other-drugs',
      title: 'Alcohol and Other Drugs',
      search: true,
      searchOptions: {
        filter: {
          tags: [1788],
        },
      },
    },
    {
      id: 'chronic-conditions',
      title: 'Chronic Conditions',
      search: true,
      searchOptions: {
        filter: {
          tags: [1789],
        },
      },
    },
    {
      id: 'population-health',
      title: 'Population Health',
      search: true,
      searchOptions: {
        filter: {
          tags: [1791],
        },
      },
    },
    {
      id: 'mental-health',
      title: 'Mental Health',
      search: true,
      searchOptions: {
        filter: {
          tags: [1790],
        },
      },
    },
    {
      id: 'workforce',
      title: 'Workforce',
      search: true,
      searchOptions: {
        filter: {
          tags: [1792],
        },
      },
    },
  ],

  search: {
    location: {
      showLocationDialog: false,
      initLocation: 'default',
      defaultLocation: {
        type: 'geography',
        id: 2298,
        default: true,
        label: 'All Regions',
        dataset: 'AUSSTATES',
        area: 954332773227.157,
        lat: -23.8617462595167,
        lng: 142.807916168283,
        kmlUrl:
          'https://devdirectorycdn.blob.core.windows.net/kml/phn/western_queensland.kml',
      },
    },
    queries: [
      {
        id: 'services',
        builder: 'directory',
        parameters: [
          { param: 'page' },
          { param: 'sort', sortField: 'Name', sortDirection: 'ASC' },
          { param: 'query' },
          // { param: 'commissionedServices' },
          // { param: 'locationRegion' },
          { param: 'serviceRegions' },
          { param: 'filters' },
        ],
      },
    ],
    searches: {
      services: {
        id: 'services',
        type: 'directory',
        pageSize: 1000,
        showSiteSupport: true,
        defaultFilter: { terms: { serviceTags: [-1] } },
      },
      favourites: {
        id: 'favourites',
        type: 'favourites',
      },
    },
  },

  assets,
  survey,
}

export default config
